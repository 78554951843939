import React from 'react';
import { Layout, Button } from 'components';
import './thank-you.scss';
const ThankYou = props => {
  return (
    <Layout>
      <div className="thank-you-page">
        <div className="wrapper">
          <span className="thank-you-page-title">Thanks for submitting</span>
          <span className="thank-you-page-subtitle">
            A member of our dedicated team will be in touch with you shortly
          </span>
          <Button to="/" className="button">
            Back to home
          </Button>
        </div>
      </div>
    </Layout>
  );
};
export default ThankYou;
